import Description from '@tebuto/forms/Description'
import Suffix from '@tebuto/forms/Suffix'
import { Field, useFormikContext } from 'formik'
import React, { useEffect, useRef } from 'react'
import ErrorMessage from './ErrorMessage'
import { Label } from './Label'

export interface FormTextAreaProps {
    name: string
    label?: string
    autoComplete?: string
    type?: string
    placeholder?: string
    className?: string
    suffix?: string
    mandatory?: boolean
    description?: string
    rows?: number
}

export default function FormTextArea({
    className = '',
    label,
    name,
    autoComplete,
    suffix,
    description,
    mandatory,
    rows = 3,
    placeholder,
    ...rest
}: FormTextAreaProps & React.HTMLAttributes<HTMLDivElement>): JSX.Element {
    const { errors, touched } = useFormikContext()
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        const textarea = textareaRef.current
        if (textarea) {
            const adjustHeight = () => {
                textarea.style.height = 'auto'
                textarea.style.height = `${textarea.scrollHeight + 10}px`
            }

            adjustHeight() // Adjust height on initial render
            textarea.addEventListener('input', adjustHeight)
            return () => textarea.removeEventListener('input', adjustHeight)
        }
    }, [])

    function CSSClasses() {
        let defaultCSS =
            'flex-1 min-w-0 block w-full px-3 py-2 border text-gray-600 placeholder:text-gray-300 border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm '
        // @ts-ignore
        if (errors[name] && touched[name]) defaultCSS += ' border-red-500 !ring-red-500'
        if (suffix) defaultCSS += ' rounded-none rounded-l-md border-r-0 '
        return defaultCSS
    }

    return (
        <div className={`${className} relative`} {...rest}>
            {label && (
                <div className="flex-col mb-3">
                    <Label htmlFor={name} mandatory={mandatory}>
                        {label}
                    </Label>
                </div>
            )}
            <div className="mt-1 flex rounded-md shadow-sm mb-2">
                <Field as="textarea" name={name} rows={rows} placeholder={placeholder} autoComplete={autoComplete} className={CSSClasses()} innerRef={textareaRef} />
                <Suffix suffix={suffix} />
            </div>
            <ErrorMessage name={name} />
            <Description description={description} />
        </div>
    )
}
