import { FormikProps } from 'formik'

// see https://stackoverflow.com/a/5219948
export const EMAIL_REGEX = /[^ ]+@[^ ]+\.[^ ]{2,6}/
export const SLUG_REGEX = /^[A-Za-z0-9\-]{3,}$/
export const PHONE_REGEX = /^\+?[0-9\s-]+$/
export const LINKED_IN_PROFILE_URL_REGEX = /^https:\/\/www\.linkedin\.com\/in\/(\w|\-)+\/?$/

export function isEmail(input: string) {
    return EMAIL_REGEX.test(input)
}

export const UNICODE_EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

// UNICODE_EMAIL_REGEX but allows multiple comma separated emails
export const UNICODE_EMAILS_REGEX =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})(,\s*(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}))*$/i

export function isFaulty(formik: FormikProps<any>, fieldId: string) {
    return formik.touched[fieldId] && formik.errors[fieldId]
}

export function isValidDachZip(zip: string): boolean {
    const germanZipRegex = /^\d{5}$/
    const swissZipRegex = /^\d{4}$/
    const australianZipRegex = /^\d{4}$/
    return germanZipRegex.test(zip) || swissZipRegex.test(zip) || australianZipRegex.test(zip)
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
    return PHONE_REGEX.test(phoneNumber)
}

export function isValidLinkedInProfileUrl(url: string): boolean {
    return LINKED_IN_PROFILE_URL_REGEX.test(url)
}
