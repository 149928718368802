'use client'

import { Button } from '@tebuto/buttons/Button'
import { classNames } from '@tebuto/functions/css'
import { useComponentTranslation } from '@tebuto/functions/translation.components'
import { ButtonSpinner } from '@tebuto/layout/Spinner'

interface FormButtonProps {
    loading?: boolean
    showResetButton?: boolean
    resetButtonEnabled?: boolean
    saveButtonEnabled?: boolean
    resetButtonLabel?: string
    saveButtonLabel?: string
    className?: string
    submitButtonClassName?: string
    resetButtonClassName?: string
}

export default function FormButtons({
    loading = false,
    showResetButton = true,
    resetButtonEnabled = true,
    saveButtonEnabled = true,
    resetButtonLabel,
    saveButtonLabel,
    className = '',
    submitButtonClassName = '',
    resetButtonClassName = ''
}: FormButtonProps): JSX.Element {
    const { t } = useComponentTranslation()

    resetButtonLabel = resetButtonLabel || t('discard')
    saveButtonLabel = saveButtonLabel || t('save')

    const submitButtonCss = `w-max
        ${saveButtonEnabled || loading ? '' : 'opacity-30 cursor-not-allowed'}
        ${showResetButton ? 'ml-5 ' : ''}
        ${submitButtonClassName}
    `

    const resetButtonCss = classNames('w-max', resetButtonEnabled ? '' : 'cursor-not-allowed opacity-30', resetButtonClassName)

    return (
        <div className={`flex items-center ${className} ${!showResetButton ? 'justify-end' : 'justify-between'}`}>
            {showResetButton && (
                <Button type="reset" outline color="secondary" tabIndex={0} disabled={!resetButtonEnabled} className={resetButtonCss}>
                    {resetButtonLabel}
                </Button>
            )}
            {
                <Button type="submit" tabIndex={0} disabled={!saveButtonEnabled || loading} className={submitButtonCss}>
                    {loading ? <ButtonSpinner /> : saveButtonLabel}
                </Button>
            }
        </div>
    )
}
